import { Routes, Route, Navigate } from "react-router-dom";
// pages
import {
  Dashboard,
  Create,
  Client,
  AgencyMember,
  AnimationFeed,
  Brand,
  NewBrand,
  PhotoRoom,
  Edit,
  Community,
  Assets,
  StudioCreator,
  // auth
  Login,
  FillInformation,
  // Register,
  ForgotPassword,
  LandingPage,
  ResetPassword,
  AcceptInvitation,
  // other
  Page404,
  MyFeed,
  Setting,
  MagicRemover,
  AIBKRemover,
  // admin
  AdminLogin,
  AdminDashboard,
  AdminSettings,
  AdminTemplates,
  AdminStatistics,
  AdminUsers,
  AdminLicense,
  AdminWebinar,
} from "pages";
import VideoTraining from "pages/VideoTraining";
import Logs from "pages/Admin/Logs";
import { TextRemover } from "pages/TextRemover";
import ImageToText from "pages/ImageToText";
import ImageToVideo from "pages/ImageToVideo";
import SearchAndReplace from "pages/SearchAndReplace";
import ColorChanger from "pages/ColorChanger";
import VoiceToImage from "pages/VoiceToImage";
import Enhance from "pages/Enhance";
import HdClarity from "pages/HdClarity";

export const PublicRoutes = () => {
  return (
    <Routes>
      <Route path="/" exact element={<LandingPage />} />
      <Route path="/dashboard" element={<Dashboard />} />
      {/* <Route path="/newbrand" element={<NewBrand />} /> */}
      <Route path="/brand" element={<NewBrand />} />
      <Route path="/create/text-to-image" element={<Create />} />
      <Route path="/create/photoshoot" element={<PhotoRoom />} />
      {/* <Route path="/create/edit" element={<Edit />} /> */}
      <Route path="/magictool/image-to-text" element={<ImageToText />} />
      <Route path="/magictool/image-enhancer" element={<Enhance />} />
      <Route path="/magictool/image-hd-clarity" element={<HdClarity />} />
      <Route path="/magictool/magic-remover" element={<MagicRemover />} />
      <Route path="/magictool/text-remover" element={<TextRemover />} />
      <Route path="/magictool/ai-background-remover" element={<AIBKRemover />} />
      <Route path="/magictool/search-replace-object" element={<SearchAndReplace />} />
      <Route path="/magictool/color-changer" element={<ColorChanger />} />
      <Route path="/magictool/voice-to-image" element={<VoiceToImage />} />
      <Route path="/animation/image-to-animation" element={<ImageToVideo />} />
      <Route path="/animation/animation-feed" element={<AnimationFeed />} />
      <Route path="/agency/clients" element={<Client />} />
      <Route path="/agency/members" element={<AgencyMember />} />
      <Route path="/assets" element={<Assets />} />
      <Route path="/community" element={<Community />} />
      <Route path="/my-feed" element={<MyFeed />} />
      <Route path="/setting" element={<Setting />} />
      <Route path="/studio-creator" element={<StudioCreator />} />
      <Route path="/video-training" element={<VideoTraining />} />
      {/* auth */}
      <Route path="/login" element={<Login />} />
      {/* <Route path="/landing-page" element={<LandingPage />} /> */}
      <Route path="/finish-registration" element={<FillInformation />} />
      {/* <Route path="/register" element={<Register />} /> */}
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/reset-password/:token" element={<ResetPassword />} />
      <Route path="/accept-invitation/:token" element={<AcceptInvitation />} />
      <Route path="*" element={<Page404 />} />
      {/* admin */}
      <Route path="/admin" exact element={<Navigate to="/admin/login" />} />
      <Route path="/admin/login" element={<AdminLogin />} />
      <Route path="/admin/dashboard" element={<AdminDashboard />} />
      <Route path="/admin/settings" element={<AdminSettings />} />
      <Route path="/admin/templates" element={<AdminTemplates />} />
      <Route path="/admin/statistics" element={<AdminStatistics />} />
      <Route path="/admin/users" element={<AdminUsers />} />
      <Route path="/admin/licenses" element={<AdminLicense />} />
      <Route path="/admin/webinar" element={<AdminWebinar />} />
      <Route path="/admin/logs" element={<Logs />} />
    </Routes>
  );
};
