// LandingPage.js
import React from 'react';
import { Link } from 'react-router-dom';
import './styles.scss';

export function LandingPage ()  {
  return (
    <div className="landing-page">
      {/* Header */}
      <header className="landing-header">
        <div className="logo">Creativio AI</div>
        <nav className="navigation">
          <Link to="/tools">Tools</Link>
          <Link to="/pricing">Pricing</Link>
          <Link to="/faq">FAQ</Link>
          <Link to="/login" className="login-button">Login</Link>
        </nav>
      </header>

      {/* Hero Section */}
      <section className="hero">
        <div className="hero-content">
          <h1>Change the Background Color of Your Photos Instantly</h1>
          <p>Upload an image and get professional results with our easy-to-use tool. No design skills required.</p>
          <div className="cta-buttons">
            <button className="upload-button">Upload Image</button>
            <Link to="/how-it-works" className="learn-more">Learn How it Works</Link>
          </div>
        </div>
        <div className="hero-image">
          <img src="https://via.placeholder.com/500" alt="Photo background editing" />
        </div>
      </section>

      {/* Features Section */}
      <section className="features">
        <h2>Why Choose PhotoRoom?</h2>
        <div className="feature-list">
          <div className="feature-item">
            <h3>Quick Results</h3>
            <p>Get fast, high-quality results in seconds with our AI-powered technology.</p>
          </div>
          <div className="feature-item">
            <h3>Easy to Use</h3>
            <p>Our tool is designed for anyone, regardless of technical skill level.</p>
          </div>
          <div className="feature-item">
            <h3>Multiple Backgrounds</h3>
            <p>Choose from a variety of colors or upload your own custom background.</p>
          </div>
        </div>
      </section>

      {/* Testimonials Section */}
      <section className="testimonials">
        <h2>What Our Users Say</h2>
        <div className="testimonial-list">
          <div className="testimonial-item">
            <p>"PhotoRoom made it so easy to change my background. I love how quick and seamless it was!"</p>
            <span>- Jane Doe</span>
          </div>
          <div className="testimonial-item">
            <p>"I use it for my online store, and the images look professional every time."</p>
            <span>- John Smith</span>
          </div>
        </div>
      </section>

      {/* Footer */}
      <footer className="landing-footer">
        <p>&copy; 2024 Creativio AI. All rights reserved.</p>
      </footer>
    </div>
  );
};
